import { Suspense, type SuspenseProps, useEffect, useState } from "react"

export function ClientOnlySuspense({ fallback, ...props }: SuspenseProps) {
  // on first render wait for mount, of next renders just set it to true
  const [isReady, setIsReady] = useState(!global.isServerOrFirstHydration)

  useEffect(() => {
    setIsReady(true)
  }, [])

  return isReady ? <Suspense {...props} fallback={fallback} /> : fallback
}
