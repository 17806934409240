import { Box, Row } from "@tokenterminal/ui/Box"
import { Divider } from "@tokenterminal/ui/Divider"
import { Stack } from "@tokenterminal/ui/Stack"
import { Text } from "@tokenterminal/ui/Text"
import Image from "next/image"
import { slidesById } from "../../home/content"
import { featuredContentCardCss } from "../common.css"

const tableData = [
  {
    groupName: "Income statement",
    rows: [
      ["Fees", "$94.64m", "$62.82m", "$103.72m", "$142.28m"],
      ["(Supply-side fees)", "$38.30m", "$27.96m", "$35.51m", "$37.28m"],
      ["Revenue", "$56.33m", "$34.85m", "$68.22m", "$105.01m"],
      ["(Expenses)", "$265.95m", "$217.14m", "$197.39m", "$208.47m"],
    ].map(([label, value1, value2, value3]) => [
      <Text size="14">{label}</Text>,
      <Text size="14" style={{ textAlign: "right" }}>
        {value1}
      </Text>,
      <Text
        size="14"
        style={{ textAlign: "right", fontFamily: "var(--font-geist-mono)" }}
      >
        {value2}
      </Text>,
      <Text
        size="14"
        style={{ textAlign: "right", fontFamily: "var(--font-geist-mono)" }}
      >
        {value2}
      </Text>,
      <Text
        size="14"
        style={{ textAlign: "right", fontFamily: "var(--font-geist-mono)" }}
      >
        {value3}
      </Text>,
    ]),
  },
]

function FinancialStatement() {
  return (
    <Box
      className={featuredContentCardCss}
      style={{
        width: "100%",
        height: "100%",
        position: "relative",
        overflow: "hidden",
        backgroundColor: slidesById.understand.accentColor,
      }}
    >
      <Stack
        gap="4x"
        style={{
          background: "#15161D",
          borderRadius: "15px",
          position: "absolute",
          left: "16px",
          top: "16px",
          zIndex: 1,
          padding: "24px",
          width: "800px",
          height: "900px",
        }}
      >
        <Row start gap="5x">
          <Image
            alt="Eth logo"
            height={32}
            width={32}
            src="https://static1.tokenterminal.com/ethereum/logo.png"
            style={{
              borderRadius: "50%",
            }}
          />
          <Stack gap="0" alignItems="flexStart">
            <Text size="20" fontWeight="500">
              Financial Statement
            </Text>
            <Text color="muted">Ethereum</Text>
          </Stack>
        </Row>
        <Divider />
        {tableData.map((group) => (
          <table
            style={{
              textAlign: "left",
              width: "550px",
            }}
          >
            <thead>
              <tr>
                <th>
                  <Text
                    size="14"
                    color="muted"
                    fontWeight="500"
                    paddingBottom="2x"
                  >
                    {group.groupName}
                  </Text>
                </th>
                <th>
                  <Text
                    size="14"
                    color="muted"
                    fontWeight="400"
                    paddingBottom="2x"
                    textAlign="right"
                  >
                    Jul 2024
                  </Text>
                </th>
                <th>
                  <Text
                    size="14"
                    color="muted"
                    fontWeight="400"
                    paddingBottom="2x"
                    textAlign="right"
                  >
                    Aug 2024
                  </Text>
                </th>
                <th>
                  <Text
                    size="14"
                    color="muted"
                    fontWeight="400"
                    paddingBottom="2x"
                    textAlign="right"
                  >
                    Sep 2024
                  </Text>
                </th>
                <th>
                  <Text
                    size="14"
                    color="muted"
                    fontWeight="400"
                    paddingBottom="2x"
                    textAlign="right"
                  >
                    Oct 2024
                  </Text>
                </th>
              </tr>
            </thead>
            <tbody>
              {group.rows.map((row) => (
                <tr>
                  <td style={{ padding: "6px 0" }}>{row[0]}</td>
                  <td style={{ padding: "6px 0" }}>{row[1]}</td>
                  <td style={{ padding: "6px 0" }}>{row[2]}</td>
                  <td style={{ padding: "6px 0" }}>{row[3]}</td>
                  <td style={{ padding: "6px 0" }}>{row[4]}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ))}
      </Stack>
      <div
        style={{
          background: `linear-gradient(160deg, rgba(21, 22, 29, 0) 30%, ${slidesById.understand.accentColor})`,
          width: "100%",
          height: "100%",
          position: "absolute",
          bottom: "0",
          right: "0",
          opacity: 0.25,
          zIndex: 1,
        }}
      />
    </Box>
  )
}

export default FinancialStatement
