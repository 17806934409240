import { Box } from "@tokenterminal/ui/Box"
import { slidesById } from "../../home/content"
import NewIllustration from "../../products/dataroom/NewIllustration"
import { featuredContentCardCss } from "../common.css"

function T1Chip() {
  return (
    <Box
      className={featuredContentCardCss}
      style={{
        width: "100%",
        height: "100%",
        position: "relative",
        overflow: "hidden",
        backgroundColor: slidesById.trust.accentColor,
      }}
    >
      <NewIllustration />
    </Box>
  )
}

export default T1Chip
