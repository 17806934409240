import { ChartLineUp, Code, Database, Table } from "@phosphor-icons/react"
import FinancialStatement from "../common/illustrations/FinancialStatement"
import T1Chip from "../common/illustrations/T1Chip"
import Top5Projects from "../common/illustrations/Top5Projects"
import Use from "../common/illustrations/Use"
import APIOutlineIllustration from "./APIOutlineIllustration"
import DataRoomOutlineIllustration from "./DataRoomOutlineIllustration"
import ExploreOutlineIllustration from "./ExploreOutlineIllustration"
import { type Feature } from "./FeatureCallouts"
import SheetsOutlineIllustration from "./SheetsOutlineIllustration"
import { BASE_PATH } from "@/constants/app.mjs"

export const slidesById = {
  understand: {
    word: "understand",
    featuredContent: <FinancialStatement />,
    backgroundImage: `${BASE_PATH}/static/images/marketing/home/hero-illustration-bank.png`,
    accentColor: "#DD995D",
    ctaText: "Learn more about Explorer",
    ctaHref: "/products/explorer",
  },
  trust: {
    word: "trust",
    featuredContent: <T1Chip />,
    backgroundImage: `${BASE_PATH}/static/images/marketing/home/hero-illustration-warehouse.png`,
    accentColor: "#925BD6",
    ctaText: "Learn more about our data pipeline",
    ctaHref: "/about#B",
  },
  explore: {
    word: "explore",
    featuredContent: <Top5Projects />,
    backgroundImage: `${BASE_PATH}/static/images/marketing/home/hero-illustration-mountains.png`,
    accentColor: "#7AFFDE",
    ctaText: "Learn more about Explorer",
    ctaHref: "/projects",
  },
  use: {
    word: "act on",
    featuredContent: <Use />,
    backgroundImage: `${BASE_PATH}/static/images/marketing/home/hero-illustration-construction.png`,
    accentColor: "#5291C1",
    ctaText: "Learn more about our API",
    ctaHref: "/products/api",
  },
}
export const slides = Object.values(slidesById)

export const featureCallouts: Array<Feature> = [
  {
    id: "industry-standard",
    title: "The industry standard for blockchain data",
    description:
      "We are on a mission to create the accounting and disclosure standards for the crypto market – and the analytics platform for institutional-grade due diligence.",
    ctaText: "Read more",
    ctaHref: "/about#industry-standard",
    image: `${BASE_PATH}/static/images/marketing/products/explorer/understand.jpg`,
  },
  {
    id: "full-stack",
    title: "A full stack onchain data platform",
    description:
      "We ensure the best possible data quality, granularity, and accuracy through our vertically integrated data supply chain.",
    ctaText: "Read more",
    ctaHref: "/about#full-stack",
    image: `${BASE_PATH}/static/images/marketing/home/callouts/ui_snippet_projects.webp`,
  },
  {
    id: "trustworthy-data",
    title: "Onchain data you can trust and act on",
    description:
      "We empower both institutional and retail investors with high-quality onchain data that serves as the foundation for informed decision-making.",
    ctaText: "Read more",
    ctaHref: "/about#onchain-data",
    image: `${BASE_PATH}/static/images/marketing/home/callouts/onchain.jpg`,
  },
  {
    id: "bloomberg",
    title: "Available on Bloomberg Terminal",
    description:
      "The Token Terminal web application is now available on the Bloomberg Terminal App Portal. To access the application inside Bloomberg, run the command APPS TOKEN GO.",
    ctaText: "Read the announcement",
    ctaHref: "/articles/crypto-fundamentals-bloomberg-launch",
    image: `${BASE_PATH}/static/images/marketing/home/callouts/ui_snippet_bloomberg.webp`,
  },
]

export const products = [
  {
    id: "terminal",
    name: "Explorer",
    subheading: "For everyone",
    description:
      "The Token Terminal Pro caters to analysts who want to consume standardized onchain data in a no-code manner and/or via Excel/CSV.",
    ctaText: "Learn more",
    ctaHref: "/products/explorer",
    icon: ChartLineUp,
    visual: ExploreOutlineIllustration,
  },
  {
    id: "sheets",
    name: "Sheets",
    subheading: "For analysts & researchers",
    description:
      "The Token Terminal Sheets plugin for Google Sheets and Excel caters to analysts who want to consume standardized onchain data in a no-code manner.",
    ctaText: "Learn more",
    ctaHref: "/products/sheets",
    icon: Table,
    visual: SheetsOutlineIllustration,
  },
  {
    id: "api",
    name: "API",
    subheading: "For data scientists & developers",
    description:
      "The Token Terminal API caters to data scientists and developers who want to consume the standardized data sets programmatically.",
    ctaText: "Learn more",
    ctaHref: "/products/api",
    icon: Code,
    visual: APIOutlineIllustration,
  },
  {
    id: "data-room",
    name: "Data Room",
    subheading: "For technical teams",
    description:
      "The Data Room caters to technical teams that want to query and analyze multichain data using SQL.",
    ctaText: "Learn more",
    ctaHref: "/products/data-room",
    icon: Database,
    visual: DataRoomOutlineIllustration,
  },
]

export const groupedTestimonials: {
  quote: string
  name: string
  company: string
  logo_url: string
  avatar: string
  socialHandle?: string
}[][] = [
  [
    {
      quote:
        "Even though the industry is open source and most of the information is publicly available on the blockchain, it can still be a lot of work to get accurate and actionable data. We’ve found that Token Terminal is consistently putting out high quality data, with everything clearly documented.\n\nThey’ve really changed the way we think about onchain data internally, since we don’t need to build our data pipelines from scratch anymore.",
      name: "Felipe Monteleagre",
      company: "Theia",
      logo_url: `${BASE_PATH}/static/images/marketing/home/testimonials/logo_theia.png`,
      avatar: `${BASE_PATH}/static/images/marketing/home/testimonials/avatar-TheiaResearch.jpg`,
      socialHandle: "TheiaResearch",
    },
    {
      quote:
        "We’re frequent and happy users of the data and dashboards available on Token Terminal. Token Terminal has done a great job focusing on standardizing KPIs that make fundamental analysis of digital assets faster and easier.",
      name: "Cosmo Jiang",
      company: "Pantera",
      logo_url: `${BASE_PATH}/static/images/marketing/home/testimonials/logo_pantera.png`,
      avatar: `${BASE_PATH}/static/images/marketing/home/testimonials/avatar-cosmo_jiang.png`,
      socialHandle: "cosmo_jiang",
    },
    {
      quote:
        "Token Terminal has been an invaluable tool in my crypto research, offering deep insights into on-chain metrics and token economics. Its user-friendly interface and robust data sets streamline my analysis, ensuring I stay ahead in the rapidly evolving crypto landscape. A must-have for anyone serious about crypto analysis.",
      name: "Ryan Rasmussen",
      company: "Bitwise",
      logo_url: `${BASE_PATH}/static/images/marketing/home/testimonials/logo_bitwise.png`,
      avatar: `${BASE_PATH}/static/images/marketing/home/testimonials/avatar-RasterlyRock.jpg`,
      socialHandle: "RasterlyRock",
    },
  ],

  [
    {
      quote:
        "As a crypto investor, I spend a lot of time trying to understand the financial performance of crypto protocols. Before Token Terminal, this meant a lot of manual work across different platforms, whereas now I’m able to access comparable metrics for hundreds of protocols all in one place. At this point, I can’t imagine doing due diligence without the Terminal.",
      name: "Sanat Kapur",
      company: "Dragonfly Capital",
      logo_url: `${BASE_PATH}/static/images/marketing/home/testimonials/logo_dragonfly.png`,
      avatar: `${BASE_PATH}/static/images/marketing/home/testimonials/avatar-kapursanat.png`,
      socialHandle: "kapursanat",
    },
    {
      quote:
        "Token Terminal saves my team hours of work by aggregating information across multiple data sources. Having revenue data for projects across multiple sectors and chains all in one place is invaluable.",
      name: "Katie Talati",
      company: "Arca",
      logo_url: `${BASE_PATH}/static/images/marketing/home/testimonials/logo_arca.png`,
      avatar: `${BASE_PATH}/static/images/marketing/home/testimonials/avatar-KatieTalati.jpg`,
      socialHandle: "KatieTalati",
    },
    {
      quote:
        "Token Terminal gives me access to high quality and well documented metrics for a broad range of projects and networks allowing me to go straight to analyzing data.",
      name: "Matt Maximo",
      company: "Grayscale",
      logo_url: `${BASE_PATH}/static/images/marketing/home/testimonials/logo_grayscale.png`,
      avatar: `${BASE_PATH}/static/images/marketing/home/testimonials/avatar-matt-maximo.jpg`,
    },
  ],
  [
    {
      quote:
        "Token Terminal gives me clean, accurate, and up-to-date data to quickly understand what’s happening in the markets and how to think about valuing assets. If you’re interested or working in investing, it’s invaluable.",
      name: "Marcos Vernemis",
      company: "Accolade Partners",
      logo_url: `${BASE_PATH}/static/images/marketing/home/testimonials/logo_accolade.png`,
      avatar: `${BASE_PATH}/static/images/marketing/home/testimonials/avatar-marcos-vernemis.jpg`,
      socialHandle: "AccoladePrtnrs",
    },
    {
      quote:
        "Token Terminal has been instrumental in revolutionizing the way we approach on-chain data for our index business. Their commitment to delivering high-quality, actionable insights has made a significant impact on our joint project to create the first fundamental indexes for crypto.\n\nWhat stands out most is their ability to distill complex data into comprehensible formats, enabling portfolio managers to make informed decisions with ease. The clarity and precision that Token Terminal brings to the table are unparalleled, making them an invaluable partner in navigating the ever-evolving blockchain landscape.",
      name: "Martin Leinweber",
      company: "MarketVector Indexes",
      logo_url: `${BASE_PATH}/static/images/marketing/home/testimonials/logo_marketvector.png`,
      avatar: `${BASE_PATH}/static/images/marketing/home/testimonials/avatar-mleinweber2.jpg`,
      socialHandle: "mleinweber2",
    },
    {
      quote:
        "Token Terminal’s API provides the automated, real-time data I need for a crypto-native approach to financial modeling. A must for staying ahead in this dynamic industry.",
      name: "Yannis Heyken",
      company: "Cherry VC",
      logo_url: `${BASE_PATH}/static/images/marketing/home/testimonials/logo_cherry_vc.png`,
      avatar: `${BASE_PATH}/static/images/marketing/home/testimonials/avatar-YannisHeyken.jpg`,
      socialHandle: "YannisHeyken",
    },
  ],
]
