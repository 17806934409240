import { Box } from "@tokenterminal/ui/Box"
import { slidesById } from "../../home/content"
import { featuredContentCardCss } from "../common.css"
import ExploreChart from "./ExploreChart"

function Top5Projects() {
  return (
    <Box
      className={featuredContentCardCss}
      style={{
        width: "100%",
        height: "100%",
        position: "relative",
        overflow: "hidden",
        backgroundColor: slidesById.explore.accentColor,
      }}
    >
      <ExploreChart
        style={{
          position: "absolute",
          left: "16px",
          top: "16px",
        }}
      />
      <div
        style={{
          background:
            "linear-gradient(160deg, rgba(21, 22, 29, 0) 0%, rgba(21, 22, 29, .5) 50%, rgba(21, 22, 29, .7)",
          width: "100%",
          height: "200px",
          position: "absolute",
          bottom: "0",
          right: "0",
        }}
      />
    </Box>
  )
}

export default Top5Projects
