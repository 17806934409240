import { Box } from "@tokenterminal/ui/Box"
import { slidesById } from "../../home/content"
import { featuredContentCardCss } from "../common.css"
import GoogleCollab from "./GoogleCollab"
import Spreadsheet from "./Spreadsheet"
import SpreadsheetDropdown from "./SpreadsheetDropdown"

function Use() {
  return (
    <Box
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      <Box
        className={featuredContentCardCss}
        style={{
          width: "100%",
          height: "100%",
          position: "relative",
          overflow: "hidden",
          backgroundColor: slidesById.use.accentColor,
        }}
      >
        <Spreadsheet
          style={{
            position: "absolute",
            left: "16px",
            top: "16px",
          }}
        />
        <GoogleCollab
          style={{
            position: "absolute",
            left: "294px",
            top: "-70px",
          }}
        />
        <SpreadsheetDropdown
          style={{
            position: "absolute",
            left: "57px",
            top: "90px",
            zIndex: 2,
          }}
        />
        <div
          style={{
            background: `linear-gradient(160deg, rgba(21, 22, 29, 0) 50%, ${slidesById.use.accentColor})`,
            width: "300%",
            height: "100%",
            position: "absolute",
            bottom: "0",
            right: "0",
            opacity: 0.75,
            zIndex: 1,
          }}
        />
      </Box>
    </Box>
  )
}

export default Use
