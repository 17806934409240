import { ArrowRight } from "@phosphor-icons/react"
import { Box } from "@tokenterminal/ui/Box"
import { Card } from "@tokenterminal/ui/Card/Card"
import { Icon } from "@tokenterminal/ui/Icon/Icon"
import { Row } from "@tokenterminal/ui/Row"
import { Stack } from "@tokenterminal/ui/Stack"
import { Text } from "@tokenterminal/ui/Text"
import { ClientOnlySuspense } from "components/ClientOnlySuspense"
import NextLink from "next/link"
import MarketingHeading from "../common/MarketingHeading"
import { products } from "./content"
import {
  headingCss,
  solutionCardCss,
  solutionsRowCss,
  rowCss,
  descriptionCss,
  solutionHeadingCss,
  gradientCss,
  illustrationContainerCss,
  cardStackCss,
  contentStackCss,
} from "./SolutionsForEveryScale.css"

export const ProductsList = ({
  omittedProduct,
}: {
  omittedProduct?: "terminal" | "api" | "data-room" | "sheets"
}) => {
  return (
    <Row className={solutionsRowCss}>
      {products
        .filter(({ id }) => id !== omittedProduct)
        .map((solution, index) => {
          const VisualComponent = solution.visual

          return (
            <Card
              key={solution.name}
              as={NextLink}
              href={solution.ctaHref}
              className={solutionCardCss}
            >
              <Stack
                gap="2x"
                className={cardStackCss}
                style={{
                  position: "relative",
                }}
              >
                <Stack className={contentStackCss} gap="0">
                  <Row alignItems="flexStart">
                    <MarketingHeading level={4} className={solutionHeadingCss}>
                      {solution.name}
                    </MarketingHeading>
                    <Icon as={ArrowRight} width="16px" />
                  </Row>
                  <Text size="14" style={{ color: "rgba(255,255,255,.6)" }}>
                    {solution.subheading}
                  </Text>
                </Stack>
                <Box position="relative">
                  <Box className={illustrationContainerCss}>
                    <ClientOnlySuspense>
                      <VisualComponent />
                    </ClientOnlySuspense>
                  </Box>
                </Box>
              </Stack>
              <Box className={gradientCss} />
            </Card>
          )
        })}
    </Row>
  )
}

const SolutionsForEveryScale = () => {
  return (
    <Stack gap={["1x", "8x"]}>
      <Row className={rowCss}>
        <MarketingHeading level={1} className={headingCss}>
          Solutions for every scale
        </MarketingHeading>
        <Text className={descriptionCss} size="16">
          Our products drive technical and non-technical teams to success,
          building off the same quality data.
        </Text>
      </Row>
      <ProductsList />
    </Stack>
  )
}

export default SolutionsForEveryScale
